import Service from "../Service";
const resource = "/frzexportpalletcustomer/";

export default {

    list(requestID) {
        return Service.post(resource + "list", {}, {
            params: { requestID: requestID },
        });
    },

    getNumberOrder(UwoCustomerID, requestID) {
        return Service.post(resource + "getNumberOrder", {}, {
            params: { UwoCustomerID, requestID: requestID },
        });
    }

};