import Service from "../Service";
const resource = "/exportpalletdetail/";

export default {

    list(ExpID, requestID) {
        return Service.post(resource + "list", {}, {
            params: { ExpID, requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

};