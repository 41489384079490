<template>
	<div>
		<s-crud
			title="Armado de Pallet"
			:filter="filter"
			:config="config"
			add
			edit
			delete
			@save="save($event)"
			@rowSelected="rowSelected($event)"
			@EditEvent="EditEvent($event)"
			@addEvent="AddEvent()"
		>
			<template scope="props">
				
				<v-alert
					v-model="alert"
					border="right"
					:color="'#9EB8F6'"
					dark
					dense
					dismissible
					shaped
					style="position:fixed;border-radius: 10px 10px 10px 10px; z-index: 5; width: 100%; right: 0px; top: 5%; margin-top: ;}"
				>
					<div>
						<v-row justify="center">
							<v-col lg="12" cols="12">
								<b>Producto Terminado:</b>
								{{ message }}
							</v-col>
						</v-row>
					</div>
				</v-alert>

				<v-container>
					<v-row v-if="!isEdit">
						<v-col cols="12">
							<v-text-field
								v-model="search"
								append-icon="mdi-magnify"
								label="Buscar"
								single-line
								hide-details
							></v-text-field>
						</v-col>
					</v-row>

					<v-row v-if="!isEdit">
						<v-col cols="12" >
							<v-data-table
								v-model="selectedSearch"
								item-key="UwoOrderProductionID"
								dense
								:headers="headersCustomer"
								:items="itemsCustomers"
								:items-per-page="5"
								@click:row="rowClickCustomer"
								:search="search"
								
							>
							<!-- :single-select="singleSelect"
								show-select -->
							<!-- :single-select="singleSelect"
							show-select -->
							</v-data-table>
						</v-col>
					</v-row>

					<v-row v-else>
						<v-col class="s-col-form" cols="12" lg="2">
							<s-text
								label="Ord. Prod" 
								v-model="select.UwoOrderProductionID"
								disabled
							></s-text>
						</v-col>
						<v-col class="s-col-form" cols="12" lg="4">
							<s-text
								label="Cliente" 
								v-model="select.CardName"
								disabled
							></s-text>
						</v-col>
						<v-col class="s-col-form" cols="12" lg="2">
							<s-text
								label="Tipo Cultivo" 
								v-model="select.TypeCropName"
								disabled
							></s-text>
						</v-col>
						<v-col class="s-col-form" cols="12" lg="2">
							<s-text
								label="Cultivo" 
								v-model="select.TypeCultiveName"
								disabled
							></s-text>
						</v-col>
						<v-col class="s-col-form" cols="12" lg="2">
							<s-text
								label="Cantidad" 
								v-model="select.UwoMaterialListNumberBoxes"
								disabled
							></s-text>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" lg="2" md="6" sm="12" class="s-col-form" v-if="!isEdit">
							<s-select
								clearable
								autocomplete
								label="Nro. Pedido"
								:items="itemsOrder"
								item-text="OrdID"
								item-value="OrdID"
								v-model="props.item.OrdID"
								ref="refOrdID"
							>
							</s-select>
						</v-col>
						<v-col cols="12" lg="2" md="6" sm="12" class="s-col-form" v-else>
							<s-text
								label="Nro. Pedido"
								v-model="select.OrdID"
								disabled
							>

							</s-text>
						</v-col>

						<v-col cols="12" lg="2" md="6" sm="12" class="s-col-form">
							<s-select-definition
								:def="1278"
								label="Turno"
								v-model="props.item.TypeTurn"
								ref="refTypeTurn"
							/>
						</v-col>
						<v-col cols="12" lg="2" md="6" sm="12" class="s-col-form">
							<s-select-definition
								v-model="props.item.TypePresentationBox"
								:def="1341"
								label="Presentación Caja"
								ref="refTypePresentationBox"
							></s-select-definition>
						</v-col>
						<v-col cols="12" lg="2" md="6" sm="12" class="s-col-form">
							<s-text
								v-model="props.item.ExpDestareWeight"
								label="Peso Descarte"
								decimal
								@input="inputWeight(props.item)"
								ref="refExpDestareWeight"
							>
							</s-text>
						</v-col>
						<v-col cols="12" lg="2" md="6" sm="12" class="s-col-form">
							<s-text
								v-model="props.item.ExpGrossWeight"
								label="Peso Bruto"
								decimal
								@input="inputWeight(props.item)"
								ref="refExpGrossWeight"
							>
							</s-text>
						</v-col>
						<v-col cols="12" lg="2" md="6" sm="12" class="s-col-form">
							<s-text
								v-model="props.item.ExpWeightNet"
								label="Peso Neto"
								decimal
								readonly
								ref="refExpWeightNet"
							>
							</s-text>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-data-table
								item-key="LlpID"
								:headers="headersProcessPallet"
								:items="itemsProcessPalletByCustomer"
							>
								<template v-slot:item.accion="{ item }">
									<v-btn
										x-small
										color="info"
										elevation="0"
										block
										rounded
										@click="newItem(item)"
									>
										<v-icon dark>
											mdi-plus
										</v-icon>
									</v-btn>
								</template>
							</v-data-table>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" lg="3" md="6" sm="12" class="s-col-form">
							<s-text
								v-model="QuantityBox"
								label="N° Cajas"
								number
								ref="refQuantityBox"
							>
							</s-text>
						</v-col>
						
					</v-row>

					<v-row>
						<v-col cols="12">
							<v-data-table
								:headers="headersProcessPalletPre"
								:items-per-page="-1"
								hide-default-footer
								:items="itemsProcessPalletPre"
							>
								<template v-slot:item.accion="{ item }">
									<v-btn
										@click="removeItem(item)"
										x-small
										color="error"
										elevation="0"
										block
									>
										<v-icon
											style="font-size: 16px !important"
											>fas fa-times</v-icon
										>
									</v-btn>
								</template>
							</v-data-table>
							<b>Total Cajas: {{TotalQuantityBox}}</b>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:accion="{ row }">
				<v-btn
					color="primary"
					x-small
					style="border-radius: 13px; margin: 10px 10px 10px 10px;"
					fab
					@click="printQR(row)"
				>
					<v-icon class="fas fa-qrcode"></v-icon>
				</v-btn>
			</template>
		</s-crud>

		
		<v-row justify="center" cols="12" style="display: none" >
			<v-col cols="12" v-for="item in rowSelect">
				
				<qr-code
					:text="
						item.ExpID +
							',' +
							item.UwoMaterialListNumberBoxes +
							',' +
							item.CardName +
							',' +
							item.FagName +
							',' +
							item.TypeCropName +
							',' +
							item.TypeCultiveName 
					"
					error-level="H"
					hidden
				>
				</qr-code>
				<br />
			</v-col>
		</v-row>
		
	</div>
</template>

<script>
	import _sExportPalletService from "@/services/FrozenProduction/ExportPalletService";
	import _sExportPalletDetailService from "@/services/FrozenProduction/ExportPalletDetailService";
	import _sExportPalletCustomerService from "@/services/FrozenProduction/ExportPalletCustomerService"
	import _sPackingService from "../../../services/FreshProduction/PackingService";
	import qrCode from "vue-qr-generator";

	export default {
		components: {
			qrCode,
		},

		data() {
			return {
				singleSelect: true,
				filter: {},
				config: {
					model: {
						ExpID: "ID",
						accion: "string"
					},
					service: _sExportPalletService,
					headers: [
						{text: "Acción", value: "accion"},
						{text: "ID", value: "ExpID"},
						{text: "Código", value: "FprLotMaterial"},
						{text: "Orden Prod.", value: "UwoOrderProductionID"},
						{text: "Tipo Presentación", value: "TypePresentationBoxName"},
						{text: "N° Cajas", value: "UwoMaterialListNumberBoxes"},
						{text: "Peso Neto", value: "ExpWeightNet"},
						{text: "Lot Prod.", value: "RcfIDRepresentative"},
						{text: "Fundo", value: "FagName"},
						{text: "Tipo Cultivo", value: "TypeCropName"},
						{text: "Cultivo", value: "TypeCultiveName"}

					],
				},
				itemsCustomers: [],
				headersCustomer: [
					{ text: "Nro. Orden Produccion", value: "UwoOrderProductionID", width: 120 },
					{ text: "Cliente", value: "UwoCustomerName", width: 250 },
					{ text: "Cultivo", value: "TypeCultiveName", width: 100 },
					{ text: "Tipo Cultivo", value: "TypeCropName", width: 100 },
					/* { text: "Centro de Produccion", value: "UfdProductionCenter", width: 250 }, */
					{ text: "Cantidad", value: "UwoMaterialListNumberBoxes", width: 50 },
					{ text: "Peso", value: "UwoPlannedQty", width: 50 },
					/* { text: "Planta Proc.", value: "UwoPlantProcessing", width: 50 }, */
				],
				selectedRowCustomer: {},
				itemsOrder: [],
				headersProcessPallet: [
					{text: "Acción", value: "accion"},
					{text: "Tipo Selección", value: "TypeSelectionFrozenName"},
					{text: "Categoría", value: "TypeFrozenCategoryName"},
					{text: "Lot Producción", value: "RcfID"},
					{text: "Fundo", value: "FagName"},
					{text: "Tipo Cultivo", value: "TypeCropName"},
					{text: "Cultivo", value: "TypeCultiveName"},
					{text: "Variedad", value: "VrtName"},
					{text: "Parihuela", value: "LlpID"},
					{text: "Cantidad Jabas", value: "LlpQuantityJaba"},
				],
				headersProcessPalletPre: [
					{text: "Acción", value: "accion"},
					{text: "Tipo Selección", value: "TypeSelectionFrozenName"},
					{text: "Categoría", value: "TypeFrozenCategoryName"},
					{text: "Lot Producción", value: "RcfID"},
					{text: "Tipo Cultivo", value: "TypeCropName"},
					{text: "Cultivo", value: "TypeCultiveName"},
					{text: "Variedad", value: "VrtName"},
					{text: "Cantidad", value: "EpdQuantityBoxes"},
				],
				search: "",
				itemsProcessPalletByCustomer: [],
				QuantityBox: 0,
				TotalQuantityBox: 0,
				itemsProcessPalletPre: [],
				itemsProcessPalletPreRemove: [],
				rowSelect: [],
				select: [],
				isEdit: false,
				message: '',
				alert: false,
				selectedSearch: []
			}
		},

		methods: {
			inputWeight(item)
			{
				item.ExpWeightNet = parseInt(item.ExpGrossWeight) - parseInt(item.ExpDestareWeight);
			},

			Initialize()
			{
				_sExportPalletCustomerService
				.list(this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.itemsCustomers = resp.data;
						console.log('initial ', this.itemsCustomers);
					}
				})
			},

			getOrderNumberByCustomer()
			{
				if(this.selectedRowCustomer.UwoCustomerID){
					_sExportPalletCustomerService
					.getNumberOrder(
						this.selectedRowCustomer.UwoCustomerID,
						this.$fun.getUserID(),
					)
					.then(r => {
						this.itemsOrder = r.data;
						
					});
				}
				
			},

			getProcessPallet()
			{
				_sExportPalletService
				.getprocesspallet(this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.itemsProcessPalletByCustomer = resp.data;
						console.log('respuesta de los pallets por cliente ', this.itemsProcessPalletByCustomer);
					}
				})
			},

			save(item) {
				/* if(item.OrdID == undefined){
					this.$fun.alert("Ingrese el número de pedido", "warning");
					this.$refs.refOrdID.focus();
					return;
				} */
				if(item.ExpGrossWeight == 0){
					this.$refs.refExpGrossWeight.error("Ingrese peso bruto");
					return;
				}
				if(item.ExpGrossWeight == 0){
					this.$refs.refExpWeightNet.error("Ingrese peso neto");
					return;
				}

				if(this.itemsProcessPalletPre.length == 0){
					this.$fun.alert("Abastesca el pallet", "warning");
					return;
				}

				

				let paletRepresentative = this.itemsProcessPalletPre[0];
				let boxLarger = this.itemsProcessPalletPre[0].EpdQuantityBoxes;
				this.itemsProcessPalletPre.forEach(element => {
					if(element.EpdQuantityBoxes > boxLarger){
						boxLarger = element.EpdQuantityBoxes;
						paletRepresentative = element;
					}
				});
				
				
				item.UwoOrderProductionID = this.selectedRowCustomer.UwoOrderProductionID;
				item.UwoLinePackingID = this.selectedRowCustomer.UwoLinePackingID;
				item.UfdProductionCenter = this.selectedRowCustomer.UfdProductionCenter;
				item.UfdProductionCenterID = this.selectedRowCustomer.UfdProductionCenterID;
				item.UwoMaterialListID = this.selectedRowCustomer.UwoMaterialListID;
				item.UwoMaterialListNumberBoxes = this.selectedRowCustomer.UwoMaterialListNumberBoxes;
				item.UwoCustomerID = this.selectedRowCustomer.UwoCustomerID;
				item.TypeCrop = this.selectedRowCustomer.TypeCrop;
				item.TypeCultive = this.selectedRowCustomer.TypeCultive;
				item.VrtID = this.selectedRowCustomer.VrtID;
				item.FagIDRepresentative = paletRepresentative.FagID;
				item.RcfIDRepresentative = paletRepresentative.RcfID;
				item.VrtID = paletRepresentative.VrtID;
				item.SecStatus = 1;
				item.UsrCreateID = this.$fun.getUserID();
				item.UsrUpdateID = this.$fun.getUserID();

				item.FrzExportPalletDetails = this.itemsProcessPalletPre;

				let itemsTotal ;
				if(this.isEdit){
					itemsTotal = [
						...this.itemsProcessPalletPre,
						...this.itemsProcessPalletPreRemove,
					];
					item.FrzExportPalletDetails = itemsTotal;
				}
				
				
				console.log('representativo ', item);
				item.save();
			},

			rowClickCustomer(item, row)
			{
				if(item !== undefined){
					this.selectedRowCustomer = item;
					this.message = item.UwoMaterialListName;
					this.alert = true;
					this.selectedSearch = [item];
					console.log('rowSelected ', item);
					this.getOrderNumberByCustomer();
				}
			},

			newItem(item)
			{

				//|| item.TypeCultive != this.selectedRowCustomer.TypeCultive
				if(item.TypeCrop != this.selectedRowCustomer.TypeCrop){
					this.$fun.alert("Los requerimientos del cliente no coinciden con el la parihuela", "warning");
					return;
				}

				if(this.QuantityBox == 0)
				{
					//this.$fun.alert("Ingrese el número de cajas", "warning");
					this.$refs.refQuantityBox.error("Ingrese el número de cajas");
					return;
				}
				console.log("print ", (this.TotalQuantityBox + parseInt(this.QuantityBox)), this.selectedRowCustomer.UwoMaterialListNumberBoxes);
				if((this.TotalQuantityBox + parseInt(this.QuantityBox)) > this.selectedRowCustomer.UwoMaterialListNumberBoxes){
					this.$fun.alert("Ya se alcanzo o esta sobrepasando el total de cajas solicitadas", "warning");
					return;
				}

				if(this.itemsProcessPalletPre.length > 0){
					let exist = this.itemsProcessPalletPre.filter(x => x.LlpID == item.LlpID);
					console.log('exist ', exist);
					if(exist.length > 0){
						this.$fun.alert("Lote de producción ya ingresado", "warning");
						return;
					}
				}
				
				let itemDetail = {
					EpdID : 0,
					ExpID : 0,
					RcfID: item.RcfID,
					FagID: item.FagID,
					EpdQuantityBoxes: this.QuantityBox,
					TypeSelectionFrozen : item.TypeSelectionFrozen,
					TypeSelectionFrozenName : item.TypeSelectionFrozenName,
					TypeFrozenCategory: item.TypeFrozenCategory,
					TypeFrozenCategoryName: item.TypeFrozenCategoryName,
					LpsID : item.LpsID,
					LlpID : item.LlpID,
					TypeCrop: item.TypeCrop,
					TypeCropName: item.TypeCropName,
					TypeCultive: item.TypeCultive,
					TypeCultiveName: item.TypeCultiveName,
					VrtID: item.VrtID,
					VrtName: item.VrtName,
					SecStatus: 1,
					UsrCreateID: this.$fun.getUserID(),
					UsrUpdateID : this.$fun.getUserID()
				};
				this.TotalQuantityBox += parseInt(this.QuantityBox);
				console.log('detail ', itemDetail);
				this.itemsProcessPalletPre.push(itemDetail);
			},

			removeItem(item)
			{
				console.log("antes ", this.itemsProcessPalletPre);
				this.TotalQuantityBox = this.TotalQuantityBox - item.EpdQuantityBoxes;
				item.SecStatus = 0;
				if(this.itemsProcessPalletPre.length > 0){
					this.itemsProcessPalletPreRemove.push(item);
				}
				this.itemsProcessPalletPre = this.itemsProcessPalletPre.filter(e => {
					return e.LlpID != item.LlpID
				});
			},

			rowSelected(rows)
			{
				this.rowSelect = [];
				if(rows.length > 0){
					this.rowSelect.push(rows[0]);
					this.select = rows[0];
				}
				
			},

			getDataEdit()
			{
				this.TotalQuantityBox = 0;
				_sExportPalletDetailService
				.list(this.select.ExpID, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.itemsProcessPalletPre = resp.data;
						resp.data.forEach(e => {
							this.TotalQuantityBox += e.EpdQuantityBoxes;
						})
					}
				})
				
			},

			EditEvent()
			{
				this.isEdit = true;
				this.itemsProcessPalletPre = [];
				this.itemsProcessPalletPreRemove = [];
				this.selectedRowCustomer = this.select;
				this.getDataEdit();
				this.getProcessPallet();
				console.log('selecte ', this.rowSelect);
			},

			AddEvent()
			{
				this.itemsProcessPalletPre = [];
				this.itemsProcessPalletPreRemove = [];
				this.isEdit = false;
			},

			printQR()
			{
				var imgAll = document.querySelector("img");
				console.log('imgAll ', imgAll);
				var Pagelink = "about:blank";
				var pwa = window.open(Pagelink, "_new");
				pwa.document.open();
				pwa.document.write(
					"<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 10);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style media='print'>  @media print  { @page { margin-left: 4cm; {} </style></head><body onload='step1()'> \n" +
						"<div class='col col-12 justify-center'>",
				);

				
				/* imgAll.forEach(element => { */
				pwa.document.write(
					"<div class='col col-4' style='margin: 20px 20px 20px 20px;'><img alt='Scan me!' style='display: block;'  src='" +
						imgAll.currentSrc +
						"' /></div>",
				);

				pwa.document.write("<br><br><div ><table border='0'>");
				pwa.document.write(
							"<tr><td colspan='4'>Datos Generales: " + 
							"<tr><td>" + "<b>"+this.rowSelect[0].CardName == null ? ' - ' :  this.rowSelect[0].CardName+ "</b>"+
							"</td><td> || " + "<b>"+this.rowSelect[0].UwoMaterialListNumberBoxes + "</b>"+
							"</td><td> || " + "<b>"+this.rowSelect[0].TypeCropName + "</b>"+
							"</td><td> || " + "<b>"+this.rowSelect[0].TypeCultiveName + "</b>"+
							" </td></tr>",
				);
				pwa.document.write("</table></div>");

				/* this.itemsDetail.forEach(detail => {
						console.log("dedffsfsf", detail);
						pwa.document.write("<br><br><div ><table border='0'>");

						pwa.document.write(
							"<tr><td>Lote Produccion: </td><td>" + detail.RcfID +"</td></tr>" +
							"<tr><td>Jabas: </td><td>" + detail.LlpQuantityJaba +"</td></tr>"
						);

						pwa.document.write(
							"<tr><td>Productor: </td><td>" + detail.PrdCardName +"</td></tr>" +
							"<tr><td>Fundo: </td><td>" + detail.FagName + " || "+ detail.FltName + "</td></tr>"
						);

						pwa.document.write(
							"<tr><td>Certificaciones: </td><td>" + detail.TcDescription +"</td></tr>",
						);

						pwa.document.write("</table></div>");
					}); */

				/* }); */

				

				pwa.document.write("</div></body></html>");
				pwa.document.close();
			}
		},

		created () {
			this.Initialize();
			this.getProcessPallet();
		},
	}
</script>